<template>
    <div v-if="isAuthenticated">
        <HeroPage v-if="pageData" :data="pageData" />

        <ContentGridFilterCollection :paging="paging" searchParams="" :collectionFilter="collectionFilter"
            contentType="journey" />
    </div>
    <div v-else-if="!isAuthenticated">
        <HeroPage v-if="pageData" :data="pageData" />

        <ContentGridFilterCollection :paging="paging" searchParams="" :collectionFilter="collectionFilter"
            :onlyShowFree="true" contentType="journey" />
    </div>
</template>

<script>
// import { fetchMediaList } from "@streampac.io/chef_sp_1";
// @import component
// import HeroVideo from "@/components/HeroVideo";
// import VideoSliderBar from "@/components/VideoSliderBar";

import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

//import JourneyFilterCollection from "@/components/JourneyFilterCollection";
import ContentGridFilterCollection from "@/components/ContentGridFilterCollection";

import HeroPage from "@/components/HeroPage";

// import { fetchVideoContentList } from "@streampac.io/chef_sp_1";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    metaInfo() {
        return {
            title: "LEAD Hockey online",
        };
    },
    data() {
        return {
            pageData: null,
            paging: {
                pageNumber: 1,
                pageSize: 50,
            },
            collectionFilter: ["Level", "Nr of Video's", "Coaches & Trainers"],
        };
    },
    // components: {
    // 	JourneyFilterCollection,
    // },
    components: {
        ContentGridFilterCollection,
        HeroPage,
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            // if (this.isAuthenticated) {
            //     fetchPageBySlug(this.isAuthenticated, "journeys-logged-in").then(
            //         (response) => {
            //             this.pageData = response;
            //         }
            //     );
            // } else {
            fetchPageBySlug(this.isAuthenticated, "journeys").then(
                (response) => {
                    this.pageData = response;
                }
            );
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
